import { LogService } from './log.service';
import { WindowService } from './window.service';
import { RadioTeltronicService } from './radioteltronic.service';
import { CustomModalService } from './custom-modal.service';
import { CameraService } from './camera.service';
import { GeoServerService } from './geo-server.service';
import { WowzaService } from './wowza.service';
import { ArcGisService } from './arc-gis.service';
import { AuditoriaService } from './auditoria.service';
import { IntegracaoALPRService } from './integracao-alpr.service';
import { G4AService } from './g4a.service';

export const CORE_PROVIDERS: any[] = [LogService, WindowService,
  RadioTeltronicService,
  CustomModalService,
  CameraService,
  GeoServerService,
  WowzaService,
  ArcGisService,
  AuditoriaService,
  IntegracaoALPRService,
  G4AService];

export * from './log.service';
export * from './window.service';
export * from './tokens';
export * from './radioteltronic.service';
export * from './custom-modal.service';
export * from './camera.service';
export * from './geo-server.service';
export * from './wowza.service';
export * from './arc-gis.service';
export * from './auditoria.service';
export * from './integracao-alpr.service';
export * from './g4a.service';



