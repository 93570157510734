export const isString = function (arg: any) {
    return typeof arg === 'string';
};

export const isObject = function (arg: any) {
    return arg && typeof arg === 'object';
};

export const isEmptyObject = function (arg: any) {
    return Object.keys(arg).length === 0 && arg.constructor === Object;
};

export const cloneObject = function (arg: Object) {
    return Object.assign({}, arg);
};

export const isArray = function (arg: any) {
    return arg && typeof arg === 'object' && arg.length;
};