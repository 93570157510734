import { MapaService } from './mapa.service';
import { FiltroCamadaService } from './filtro-camada.service';
import { ScriptService } from './script.service';

export const CORE_PROVIDERS: any[] = [MapaService,
    FiltroCamadaService,
    ScriptService
];

export * from './mapa.service';
export * from './filtro-camada.service';
export * from './script.service';
// export * from './camadas/camada-ocorrencias-alpr.service';