import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { BaseService } from '@xplat-angular-workspace/core/base';
import { tap, map } from 'rxjs/operators';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { forceEncode, isArray, isEmptyObject } from '@xplat-angular-workspace/utils';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class GeoServerService extends BaseService {
    private static URL_GEOSERVER_PRO_INTERNAL = 'http://lpmpa-geo4:8080/geoserver';

    doDiscoverFields(layerData): Observable<any> {
        return this.http.get(layerData.fieldDiscoveryUrl)
            .pipe(
                catchError(this.handleError('doDiscoverFields', {}))
            );
    }

    getDistinctFieldValues(layerData, field): Observable<any> {
        let dataInputsURLChunk = `attribute=${field};features=@xlink:href=${layerData.gisInternalAddress ? layerData.gisInternalAddress : GeoServerService.URL_GEOSERVER_PRO_INTERNAL}/${layerData.namespace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerData.layerID}&propertyName=${field}`;
        let url = `${layerData.getDistinctValuesUrl}&dataInputs=${forceEncode(dataInputsURLChunk)}`;

        return this.http.get(url)
            .pipe(
                map(res => {
                    return (res as any).features.map(f => {
                        return { key: f.properties.value, value: f.properties.value };
                    });
                }),
                catchError(this.handleError('getDistinctFieldValues', []))
            );
    }

    getLayerSLD(layerData): Observable<any> {
        return this.http.get(layerData.getSLDUrl, { responseType: 'text' })
            .pipe(
                map(res => {
                    // this.log.debug(this.extractRulesFromSLD(res));
                    return this.extractRulesFromSLD(res)
                }),
                catchError(this.handleError('getLayerSLD', {}))
            );
    }

    // getDiscoveredFields(mappedDiscovery, layerName) {
    //     mappedDiscovery = mappedDiscovery.featureTypes.filter(ft => {
    //         return ft.typeName == layerName;
    //     })[0];

    //     return mappedDiscovery;
    // }

    doGetFeature(layerData, filterValues, isFilter = false): Observable<any> {
        let cqlFilter = [];
        Object.keys(filterValues).map(fv => {
            let filterValue = filterValues[fv] as string;
            if (filterValue != '') {
                cqlFilter.push(`strToLowerCase(${fv}) LIKE '%${filterValue.toLowerCase()}%'`);
            }
        });
        let cqlFilterString = cqlFilter.join(' AND ');
        let cqlFilterFromLayer = (layerData.layerOptions || {}).cql_filter;
        if (cqlFilterFromLayer) {
            cqlFilterString += ` AND (${cqlFilterFromLayer})`;
        }

        if (isFilter) {
            return new Observable((observer) => {
                // observable execution
                observer.next({ appliedFilter: cqlFilterString });
                observer.complete();
            });
            // return of({appliedCqlFilter: cqlFilterString });
        } else {
            if (layerData.filterFromMapSources && !isEmptyObject(layerData.filterFromMapSources)) {
                cqlFilterString += ` AND (${layerData.filterFromMapSources})`;
            }

            return this.http.get(`${layerData.getFeatureUrl}&typeNames=${layerData.layerID}&cql_filter=${cqlFilterString.replace(/%/g, '%25')}`)
                .pipe(
                    tap(res => { (res as any).appliedFilter = cqlFilterString }),
                    catchError(this.handleError('doGetFeature', {}))
                );
        }
    }

    doGetFeatureByBBOX(layerData, bboxString): Observable<any> {
        //TODO: 4326 vai ficar sempre ali, fixo?
        return this.http.get(`${layerData.getFeatureUrl}&typeNames=${layerData.layerID}&bbox=${bboxString},EPSG:4326`)
            .pipe(
                // tap(res => { (res as any).appliedFilter = cqlFilterString }),
                catchError(this.handleError('doGetFeatureByBBOX', {}))
            );
    }

    private extractRulesFromSLD(sldText) {
        let rules = {};
        let rulesAsCqlFilters = [];
        let rulesAsCqlFiltersString;
        try {
            const parser = new DOMParser();
            const xml = parser.parseFromString(sldText, 'text/xml');
            const obj = this.ngxXml2jsonService.xmlToJson(xml);
            obj['sld:StyledLayerDescriptor']['sld:NamedLayer']['sld:UserStyle']['sld:FeatureTypeStyle']['sld:Rule'].map(r => {
                rules[r['sld:Name']] = [];
                //isArray
                let propertyIsEqualTo = r['ogc:Filter']['ogc:And']
                    ? r['ogc:Filter']['ogc:And']['ogc:PropertyIsEqualTo']
                    : r['ogc:Filter']['ogc:PropertyIsEqualTo'];
                if (isArray(propertyIsEqualTo)) {
                    propertyIsEqualTo.map(sentences => {
                        rules[r['sld:Name']].push(`${sentences['ogc:PropertyName']} = '${sentences['ogc:Literal']}'`);
                    });
                } else {
                    rules[r['sld:Name']].push(`${propertyIsEqualTo['ogc:PropertyName']} = '${propertyIsEqualTo['ogc:Literal']}'`);
                }
            });
            Object.keys(rules).map(k => {
                rulesAsCqlFilters.push(`(${rules[k].join(' AND ')})`);
            });
            rulesAsCqlFiltersString = `(${rulesAsCqlFilters.join(' OR ')})`;
        } catch (ex) {
            this.log.error(ex);
            return null;
        } finally {
            return rulesAsCqlFiltersString;
        }
    }

    constructor(private http: HttpClient, private ngxXml2jsonService: NgxXml2jsonService, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
