import { Injectable } from '@angular/core';
import { BaseService, MAP_SOURCE_ARCGIS, MAP_SOURCE_GEOSERVER, MAP_SOURCE_CUSTOM_GEOJSON } from '@xplat-angular-workspace/core';
import * as EsriLeaflet from 'esri-leaflet';
import * as Leaflet from 'leaflet';
import * as WMSLeaflet from 'leaflet.wms';
import { FiltroCamadaService } from './filtro-camada.service';
import { isEmptyObject, cloneObject } from '@xplat-angular-workspace/utils';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../config/mapa.service.config';
import { URL_GEOSERVER_PRO_REST_STYLES, URL_GEOSERVER_PRO_WPS_UNIQUE } from '../local-tokens';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class MapaService extends BaseService {
    private overlayRemovedSource = new BehaviorSubject<any>(null);

    private static IDENTIFY_IGNORE_FIELDS = ['GEOM', 'SHAPE', 'SE_ANNO_CAD_DATA', 'Shape', 'description'/*, 'CREATED_USER', 'LAST_EDITED_USER'*/];
    private static IDENTIFY_FIELDS_LIMIT = 20;

    private map: any;

    public mappedBasemaps: any = {
        satelite: { title: 'Satélite' },
        osm: { title: 'OpenStreetMap' },
        cinza: { title: 'Cinza' }
    };

    private utilsLayers: any = {};

    get overlayRemoved$() {
        return this.overlayRemovedSource.asObservable();
    }

    public invalidateMapSize() {
        if (this.map) {
            setTimeout(() => {
                this.map.invalidateSize();
            });
        }
    }

    public clearFiltersLayers(reload = true, specificLayer?) {
        if (this.utilsLayers.searchResultsLayer) {
            this.utilsLayers.searchResultsLayer.layer.clearLayers();
            // if (this.utilsLayers.searchResultsLayer.managedLayer.filtered) {
            if (this.utilsLayers.searchResultsLayer.managedLayers) {
                Object.keys(this.utilsLayers.searchResultsLayer.managedLayers).map(l => {
                    if (!specificLayer || specificLayer == l) {
                        this.applyLayerOptionsOnLayer(this.utilsLayers.searchResultsLayer.managedLayers[l], this.utilsLayers.searchResultsLayer.managedLayers[l].layerOptions, reload, true, true);
                    }
                });
            }
        }
        // this.applyLayerOptionsOnLayer(this.utilsLayers.searchResultsLayer.managedLayer,
        //     this.utilsLayers.searchResultsLayer.managedLayer.layerOptions, reload);
        // // }}
    }

    // public displaySearchResults() {

    // }

    // private storeMapDefaults(mappedLayer) {
    //     if (mappedLayer.source == MAP_SOURCE_GEOSERVER) {
    //         mappedLayer.defaults = {
    //             overlayOptions: cloneObject(mappedLayer.layerSource._overlay.options),
    //             overlayWmsParams: cloneObject(mappedLayer.layerSource._overlay.wmsParams),
    //             options: cloneObject(mappedLayer.layerSource.options)
    //         };
    //     }
    // }

    private getLayerHandler(mappedLayer) {
        if (typeof mappedLayer.layerHandler == 'string') {
            mappedLayer.layerHandler = this[mappedLayer.layerHandler];
        }
    }

    public buildLayers(mapInstance, camadasIniciais) {
        console.log(this.getApiKey());
        this.map = mapInstance;

        // Mapeando as camadas
        Object.keys(config.mappedLayers).map(ml => {
            let mappedLayer = config.mappedLayers[ml];
            if (mappedLayer.source == MAP_SOURCE_ARCGIS) { // ArcGIS
                // Camada
                mappedLayer.layer = EsriLeaflet.dynamicMapLayer({
                    url: `${mappedLayer.url}?apikey=${this.getApiKey()}`,
                    layers: [mappedLayer.layerID],
                    useCors: false,
                    f: 'image',
                    // layerDefs: { 1: "NOME_EST LIKE '%Grupamento%'" }
                });
                if (mappedLayer.layerOptions) {
                    Object.assign(mappedLayer.layer.options, mappedLayer.layerOptions);
                }
                mappedLayer.fieldDiscoveryUrl = `${mappedLayer.url}/${mappedLayer.layerID}?f=json&apikey=${this.getApiKey()}`;
                mappedLayer.getFeatureUrl = `${mappedLayer.url}/${mappedLayer.layerID}/query?geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&returnGeometry=true&outSR=4326&f=json&apikey=${this.getApiKey()}`;
                mappedLayer.getLegendUrl = `${mappedLayer.url}/legend?apikey=${this.getApiKey()}&f=json`;
                mappedLayer.legendType = 'base64';

                // Identify
                mappedLayer.identify = EsriLeaflet.identifyFeatures({ url: mappedLayer.url, requestParams: { apikey: this.getApiKey() } } as any).on(this.map)
                    .layers(`visible:${mappedLayer.layerID}`) // http://esri.github.io/esri-leaflet//api-reference/tasks/identify-features.html#methods
                    .tolerance(5);
            } else if (mappedLayer.source == MAP_SOURCE_GEOSERVER) { // GEOSERVER
                mappedLayer.layerSource = new WMSLeaflet.Source(mappedLayer.url, {
                    format: 'image/png8',
                    transparent: true,
                    tiled: true,
                    info_format: 'application/json',
                    maxZoom: 22,
                    identify: false,
                    apikey: this.getApiKey()
                });
                if (mappedLayer.layerOptions) {
                    Object.assign(mappedLayer.layerSource._overlay.options, mappedLayer.layerOptions);
                    Object.assign(mappedLayer.layerSource._overlay.wmsParams, mappedLayer.layerOptions);
                    Object.assign(mappedLayer.layerSource.options, mappedLayer.layerOptions);
                    // mappedLayer.defaults = {
                    //     overlayOptions: cloneObject(mappedLayer.layerSource._overlay.options),
                    //     overlayWmsParams: cloneObject(mappedLayer.layerSource._overlay.wmsParams),
                    //     options: cloneObject(mappedLayer.layerSource.options)
                    // };
                }
                mappedLayer.fieldDiscoveryUrl = `${mappedLayer.url}?service=WFS&REQUEST=describeFeatureType&outputFormat=application/json&exceptions=application/json&apikey=${this.getApiKey()}`;
                mappedLayer.getFeatureUrl = `${mappedLayer.url}?service=WFS&REQUEST=GetFeature&outputFormat=application/json&exceptions=application/json&version=2.0.0&apikey=${this.getApiKey()}`;
                mappedLayer.layer = mappedLayer.layerSource.getLayer(mappedLayer.layerID);
                mappedLayer.getSLDUrl = `${mappedLayer.urlBaseStyles ? mappedLayer.urlBaseStyles : URL_GEOSERVER_PRO_REST_STYLES}/workspaces/${mappedLayer.namespace}/styles/${mappedLayer.sldName}.sld?apikey=${this.getApiKey()}`;
                mappedLayer.getDistinctValuesUrl = `${mappedLayer.urlBaseUniqueValues ? mappedLayer.urlBaseUniqueValues : URL_GEOSERVER_PRO_WPS_UNIQUE}?apikey=${this.getApiKey()}&identifier=gs:Unique&rawDataOutput=result%3Dformat@mimetype%3Dapplication%2Fjson&request=Execute&service=WPS&version=1.0.0`;
                mappedLayer.getLegendUrl = `${mappedLayer.url}?service=WMS&REQUEST=GetLegendGraphic&FORMAT=image/png&layer=${mappedLayer.layerID}&exceptions=application/json&apikey=${this.getApiKey()}`;
                mappedLayer.legendType = 'image';
            }
        });

        // Basemaps
        this.mappedBasemaps.satelite.layer = EsriLeaflet.basemapLayer('Imagery');
        this.mappedBasemaps.osm.layer = Leaflet.tileLayer('//api.procempa.com.br/apiman-gateway/tser/mapas-base-api/1.0/osm-procempa-2/{z}/{x}/{y}.png',
            {
                maxZoom: 22,
                attribution: "©Dados <a href='http://openstreetmap.org/' target='_blank'>OpenStreetMap</a>, Estilos <a href='http://www.procempa.com.br' target='_blank'>Procempa</a>"
            }
        );
        this.mappedBasemaps.cinza.layer = Leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: 24,
            attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
        }).addTo(this.map);

        // Montagem para ControlLayers. Adiciona todos os basemaps e layers "comuns". Aqueles customizados
        // GeoJSON serão adicionados posteriormente.
        let baselayers = {}, overlays = {};
        Object.keys(this.mappedBasemaps).map((mb) => {
            let mappedBasemap = this.mappedBasemaps[mb];
            baselayers[mappedBasemap.title] = mappedBasemap.layer;
        });
        Object.keys(config.mappedLayers).map((ml) => {
            let mappedLayer = config.mappedLayers[ml];
            if (mappedLayer.source != MAP_SOURCE_CUSTOM_GEOJSON) { // ArcGIS
                overlays[mappedLayer.title] = mappedLayer.layer;
            }
        });

        // Layers Handlers
        this.mapArcGISIdentifyEvents();
        this.geoserverIdentifyEvents();

        let layersOptions: any = { sortLayers: true/*, sortFunction: sortLayers*/ };
        let layerControl = Leaflet.control.layers(baselayers, overlays, layersOptions).addTo(this.map);

        // Custom GeoJSON Layers
        Object.keys(config.mappedLayers).map((ml) => {
            let mappedLayer = config.mappedLayers[ml];
            if (mappedLayer.source == MAP_SOURCE_CUSTOM_GEOJSON) {
                this.getLayerHandler(mappedLayer);
                if (mappedLayer.layerHandler) {
                    mappedLayer.layerHandler.initialize(this.map, layerControl, mappedLayer.title, mappedLayer.layerID);
                }
            }
        });

        // Utils Layers
        let searchResultsLayerPane = this.map.createPane('search-results');
        this.map.getPane('search-results').style.zIndex = 200;

        this.utilsLayers.searchResultsLayer = {
            layer: Leaflet.geoJSON([] as any, {
                pane: searchResultsLayerPane,
                pointToLayer: (feature, latlng) => {
                    return Leaflet.circleMarker(latlng, {
                        radius: 18,
                        color: '#00A8E8',
                        weight: 4,
                        opacity: 1,
                        fillOpacity: 0,
                        pane: searchResultsLayerPane
                    });
                }
            })
        };

        this.utilsLayers.cdlResultsLayer = {
            layer: Leaflet.geoJSON([] as any, {
                pane: searchResultsLayerPane,
                pointToLayer: (feature, latlng) => {
                    return Leaflet.marker(latlng, {
                        icon: Leaflet.icon({
                            iconUrl: 'assets/user.png',
                            iconSize: [30, 30]
                        }),
                        pane: searchResultsLayerPane
                    });
                }
            })
        };
        this.utilsLayers.searchResultsLayer.layer.addTo(this.map);
        this.utilsLayers.cdlResultsLayer.layer.addTo(this.map);

        // Map Handlers
        this.mapPopupEvents();
        this.mapGeneralEvents();
        if (camadasIniciais) {
            this.loadInitialLayers(camadasIniciais, layerControl);
        }
    }

    private loadInitialLayers(camadasIniciais, layerControl) {
        camadasIniciais.forEach(camada => {
            let mappedLayer = config.mappedLayers[camada];
            if (mappedLayer) {
                if (mappedLayer.source == MAP_SOURCE_CUSTOM_GEOJSON) {
                    this.map.addLayer(mappedLayer.layerHandler.layer);
                } else {
                    this.map.addLayer(mappedLayer.layer);
                }
                // layerControl.addOverlay(occurrencesLayerGroup, layerName);
            }
        });
    }

    private getMappedLayerByTitle(title: string) {
        let layerData, layerKey;
        Object.keys(config.mappedLayers).map((ml) => {
            let mappedLayer = config.mappedLayers[ml];
            if (mappedLayer.title == title) { // ArcGIS
                layerData = mappedLayer;
                layerKey = ml;
            }
        });

        return { layerData, layerKey };
    }

    private buildIdentifyPopup(data, title, latlng, openPopup = false, mappedLayer) {
        //TODO: Fica assim?
        if (!data || !data.features) {
            return '';
        }

        var conteudo = `<div class="conteudo-paginado"><h4>${title}</h4>`;

        var objetoTemplate = {};
        var camposInseridos = 0;
        var atingiuLimite = false;
        var featureProperties = (data.features[0] || {}).properties;
        if (featureProperties) {
            Object.keys(featureProperties).map((propertyName) => {
                var propertyValue = featureProperties[propertyName];
                //if (layerData.config && layerData.config.filter && layerData.config.filter.whitelistedFields) {
                if (!MapaService.IDENTIFY_IGNORE_FIELDS.includes(propertyName)) {
                    let temConfigIdentify = mappedLayer.config && mappedLayer.config.identify && mappedLayer.config.identify.whitelistedFields;
                    if (!temConfigIdentify || (temConfigIdentify && mappedLayer.config.identify.whitelistedFields.includes(propertyName))) {
                        if (camposInseridos < MapaService.IDENTIFY_FIELDS_LIMIT) {
                            let temAlias = mappedLayer.config && mappedLayer.config.identify && mappedLayer.config.identify.aliases && mappedLayer.config.identify.aliases[propertyName];
                            let alias = temAlias ? mappedLayer.config.identify.aliases[propertyName] : propertyName;
                            objetoTemplate[propertyName] = propertyValue;
                            let temMidia = mappedLayer.config && mappedLayer.config.identify && mappedLayer.config.identify.media && mappedLayer.config.identify.media[propertyName];
                            let midia = temMidia ? mappedLayer.config.identify.media[propertyName] : null;
                            let valorTemplate = `{${propertyName}}`;
                            if (midia) {
                                if (midia == 'image' || midia.type == 'image') {
                                    let valorPropriedadeMidia = objetoTemplate[propertyName];
                                    let jsonPropriedadeMidia = null;
                                    try {
                                        jsonPropriedadeMidia = JSON.parse(valorPropriedadeMidia);
                                    } catch (ex) {
                                        console.log('Mídia não é JSON...');
                                    }
                                    // Só trata casos de array de string, por enquanto...
                                    if (jsonPropriedadeMidia && jsonPropriedadeMidia.map) {
                                        jsonPropriedadeMidia.map((pm, index) => {
                                            // let urlImagem = `${midia.baseUrl ? (midia.baseUrl + '/') : ''}${pm}`;
                                            let urlImagem = pm;
                                            if (midia.baseUrl) {
                                                urlImagem = midia.baseUrl.replace(/\[id\]/, pm);
                                            }
                                            if (index == 0) {
                                                valorTemplate = `<img class="zoom" src="${urlImagem}" style="width: 100%"/>`;
                                            } else {
                                                valorTemplate += `<img class="zoom" src="${urlImagem}" style="width: 100%"/>`;
                                            }
                                        });
                                    } else {
                                        valorTemplate = `<img src="${valorPropriedadeMidia}" style="width: 100%"/>`;
                                    }
                                }
                            }
                            conteudo += `<strong>${alias}: </strong>${valorTemplate}<br>`;
                            // '<strong>' + propertyName + '</strong>{' + propertyName + '}<br>';
                            camposInseridos++;
                        } else if (!atingiuLimite) {
                            atingiuLimite = true;
                            conteudo += '<strong>...</strong>';
                        }
                    }
                }
            });
        }

        conteudo += '</div>';
        conteudo = Leaflet.Util.template(conteudo, objetoTemplate);

        if (openPopup) {
            var popup = Leaflet.popup()
                .setLatLng(latlng)
                .setContent(conteudo)
                .openOn(this.map);
        } else {
            if (title == 'Árvores') {
                var audio = new Audio('assets/hit-bush01.wav');
                audio.play();
            }
            return conteudo;
        }
    }

    private mapArcGISIdentifyEvents() {
        this.map.on('click', (event) => {
            Object.keys(config.mappedLayers).map(ml => {
                let mappedLayer = config.mappedLayers[ml];
                if (mappedLayer.source == MAP_SOURCE_ARCGIS && mappedLayer.identify) {
                    mappedLayer.identify.at(event.latlng);
                    mappedLayer.identify.run((error, featureCollection, response) => {
                        if (this.map.hasLayer(mappedLayer.layer)) {
                            if (error || featureCollection.features.length === 0) {
                                return;
                            }
                            this.buildIdentifyPopup(featureCollection, mappedLayer.title, event.latlng, true, mappedLayer);
                        }
                    })
                };
            });
        });
    }

    private concatenateFieldValues(from, to) {
        Object.keys(from).map(fkey => {
            Object.keys(to).map(tkey => {
                if (tkey == fkey) {
                    to[tkey] += ` AND ${from[fkey]}`;
                }
            });
        });
    }

    markCDLLocation(data) {
        let geojsonLayer = this.utilsLayers.cdlResultsLayer.layer;
        geojsonLayer.clearLayers();

        let featureCDL = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: [data.longitude, data.latitude]
            }
        };

        geojsonLayer.addData(featureCDL);

        this.map.flyToBounds(geojsonLayer.getBounds(), { maxZoom: 16 });
    }

    clearCDLLocation() {
        let geojsonLayer = this.utilsLayers.cdlResultsLayer.layer;
        geojsonLayer.clearLayers();
    }

    private mapGeneralEvents() {
        this.map.on('click', (event) => {
            // this.camadaTeltronicService.mapClickHandler();
        });

        this.map.on('overlayadd', (event) => {
            // console.log(`LAYER ADICIONADO: ${event.name}`);
            let layerInfo = this.getMappedLayerByTitle(event.name);
            // console.log(layerInfo);
            layerInfo.layerData.enabled = true;
            try {
                if (layerInfo.layerData.layerHandler && layerInfo.layerData.layerHandler.mapOverlayAddHandler) {
                    layerInfo.layerData.layerHandler.mapOverlayAddHandler();
                }
                // this.storeMapDefaults(layerInfo.layerData);
            } catch (ex) {
                console.log('IGNORANDO ERRO', ex);
            }
            this.filtroCamadaService.mapLayerFilter(layerInfo);
        });

        this.map.on('overlayremove', (event) => {
            let layerInfo = this.getMappedLayerByTitle(event.name);
            layerInfo.layerData.enabled = false;
            if (this.utilsLayers.searchResultsLayer.managedLayers
                && !isEmptyObject(this.utilsLayers.searchResultsLayer.managedLayers)
                && this.utilsLayers.searchResultsLayer.managedLayers[layerInfo.layerData.title]) {
                this.clearFiltersLayers(false, layerInfo.layerData.title);
            }
            // this.filtroCamadaService.limparFiltros(layerInfo.layerData);
            this.overlayRemovedSource.next(layerInfo.layerData);
            try {
                if (layerInfo.layerData.layerHandler && layerInfo.layerData.layerHandler.mapOverlayRemoveHandler) {
                    layerInfo.layerData.layerHandler.mapOverlayRemoveHandler();
                }
            } catch (ex) {
                console.log('IGNORANDO ERRO', ex);
            }
        });

        this.filtroCamadaService.searchResults$.subscribe(res => {
            if (!isEmptyObject(res) && res.layerData.enabled) {
                if (!this.utilsLayers.searchResultsLayer.managedLayers) {
                    this.utilsLayers.searchResultsLayer.managedLayers = {};
                } else {
                    Object.keys(this.utilsLayers.searchResultsLayer.managedLayers).map(l => {
                        if (l != res.layerData.title && this.utilsLayers.searchResultsLayer.managedLayers[l].filterSubscription) {
                            this.utilsLayers.searchResultsLayer.managedLayers[l].filterSubscription.unsubscribe();
                            this.utilsLayers.searchResultsLayer.managedLayers[l].filterSubscription = null;
                        }
                    });
                }

                // if (!this.utilsLayers.searchResultsLayer.managedLayers[res.layerData.title]) {

                // }

                // if (this.utilsLayers.searchResultsLayer.managedLayer && this.utilsLayers.searchResultsLayer.managedLayer.filterSubscription && this.utilsLayers.searchResultsLayer.managedLayer != res.layerData) {
                //     this.utilsLayers.searchResultsLayer.managedLayer.filterSubscription.unsubscribe();
                //     this.utilsLayers.searchResultsLayer.managedLayer.filterSubscription = null;
                // }
                // console.log('RESULTADOS DA CAMADA GERENCIADA?', this.utilsLayers.searchResultsLayer.managedLayer == res.layerData);

                this.utilsLayers.searchResultsLayer.managedLayers[res.layerData.title] = res.layerData;
                this.utilsLayers.searchResultsLayer.managedLayers[res.layerData.title].filtered = res.isFilter;
                //this.mapaService.displaySearchResults(res);
                if (!res.isFilter) {
                    let geojsonLayer = this.utilsLayers.searchResultsLayer.layer;
                    geojsonLayer.clearLayers();

                    if ((res.results && res.results.length > 0)
                        || (res.results.features && res.results.features.length > 0)) {
                        geojsonLayer.addData(res.results);

                        if (!res.layerData.layerHandler || !res.layerData.layerHandler.shouldPolling) {
                            this.map.flyToBounds(geojsonLayer.getBounds(), { maxZoom: 16 }/*.pad(0.1)*/);
                        }
                    }
                } else {
                    let newOptions = {
                        cql_filter: res.results.appliedFilter
                    };

                    // this.concatenateFieldValues(res.layerData.layerSource._overlay.options, newOptions);
                    // this.concatenateFieldValues(res.layerData.layerSource._overlay.wmsParams, newOptions);
                    // this.concatenateFieldValues(res.layerData.layerSource.options, newOptions);
                    // Object.assign(res.layerData.layerSource._overlay.options, newOptions);
                    // Object.assign(res.layerData.layerSource._overlay.wmsParams, newOptions);
                    // Object.assign(res.layerData.layerSource.options, newOptions);
                    this.applyLayerOptionsOnLayer(res.layerData, newOptions, true, true);

                    // res.layerData.layerSource._overlay.setParams({});
                }

                // res.layerData.layerSource._overlay.setParams({CQL_FILTER:res.results.appliedCqlFilter});

                // res.layerData.layerSource._overlay.setParams({fake: Date.now()}, false);

                // this.map.fitBounds(geojsonLayer.getBounds()/*.pad(0.1)*/);
            }
        });
    }

    private applyLayerOptionsOnLayer(to, from, reload = true, isFilter = true, isClear = false) {
        if (to.source == MAP_SOURCE_GEOSERVER) {
            // let hasDefaults = to.defaults && !isEmptyObject(to.defaults);
            Object.assign(to.layerSource._overlay.options, from);
            Object.assign(to.layerSource._overlay.wmsParams, from);
            Object.assign(to.layerSource.options, from);

            if (isClear) {
                // mappedLayer.defaults = {
                //     overlayOptions: cloneObject(mappedLayer.layerSource._overlay.options),
                //     overlayWmsParams: cloneObject(mappedLayer.layerSource._overlay.wmsParams),
                //     options: cloneObject(mappedLayer.layerSource.options)
                // };
                // to.layerSource._overlay.options = cloneObject(to.defaults.overlayOptions);
                // to.layerSource._overlay.wmsParams = cloneObject(to.defaults.overlayWmsParams);
                // to.layerSource.options = cloneObject(to.defaults.options);
                if (!from || !from.cql_filter) {
                    delete to.layerSource._overlay.options.cql_filter;
                    delete to.layerSource._overlay.wmsParams.cql_filter;
                    delete to.layerSource.options.cql_filter;
                }
            }

            if (reload) {
                to.layerSource._overlay.setParams({});
            }
        } else if (to.source == MAP_SOURCE_ARCGIS) {
            if (isClear) {
                to.layer.setLayerDefs(null);
            } else {
                let layerDefs = {};
                layerDefs[to.layerID] = from.cql_filter;
                // to.layer.options.requestParams.layerDefs = layerDefs;
                to.layer.setLayerDefs(layerDefs);
            }
        } else if (to.source == MAP_SOURCE_CUSTOM_GEOJSON) {
            to.layerHandler.setFilters(from, to, isFilter);
        }
    }

    private mapPopupEvents() {
        this.map.on('popupopen', (event) => {
            // if (event.popup.options.className === 'rt') {
            //     this.camadaTeltronicService.popupOpenHandler(event);
            // } else if (event.popup.options.className === 'horus') {
            //     this.camadaHorusService.popupOpenHandler(event);
            // } else if (event.popup.options.className === 'ocorrencias') {
            //     this.camadaOcorrenciasAlprService.popupOpenHandler(event);
            // }
        });

        this.map.on('popupclose', (event) => {
            // if (event.popup.options.className === 'rt') {
            //     this.camadaTeltronicService.popupCloseHandler(event);
            // } else if (event.popup.options.className === 'horus') {
            //     this.camadaHorusService.popupCloseHandler(event);
            // } else if (event.popup.options.className === 'ocorrencias') {
            //     this.camadaOcorrenciasAlprService.popupCloseHandler(event);
            // }
        })
    }

    private mostrarDadosMultiplosGetFeatureInfo(features, mappedLayer) {
        var conteudo = '<div id="popup-info" class="popup-info">';

        features.forEach((feature) => {
            conteudo += this.buildIdentifyPopup(feature.dados, feature.titulo, feature.latlng, false, mappedLayer);
        });

        conteudo += '</div>';

        //TODO: Tratar paginação
        this.map.openPopup(conteudo, features[0].latlng, { autoPan: false });
        // $('#popup-info').hide();
        setTimeout(function () {
            // $('#popup-info').easyPaginate({
            //     paginateElement: '.conteudo-paginado',
            //     elementsPerPage: 1,
            //     effect: 'fade',
            //     currentPage: 0
            // });
            // $('#popup-info').show();
        }, 300);
    }

    private geoserverIdentifyEvents() {
        this.map.on('click', (evt) => {
            var responses = [];

            var layersParaIdentificar = [];
            var respostasRecebidas = 0;

            Object.keys(config.mappedLayers).map(ml => {
                let mappedLayer = config.mappedLayers[ml];
                if (mappedLayer.source == MAP_SOURCE_GEOSERVER) {
                    if (mappedLayer.layerSource.getIdentifyLayers().length > 0) {
                        if (mappedLayer.specialPopup) {
                            layersParaIdentificar = [ml];
                        } else {
                            layersParaIdentificar.push(ml);
                        }
                    }
                }
            });

            layersParaIdentificar.forEach((name) => {
                let mappedLayer = config.mappedLayers[name];
                var source = mappedLayer.layerSource;
                try {
                    source.getFeatureInfo(
                        evt.containerPoint,
                        evt.latlng,
                        source.getIdentifyLayers(),
                        (latlng, info) => {
                            responses.push({ dados: JSON.parse(info), latlng: evt.latlng, titulo: mappedLayer.title });
                            respostasRecebidas++;
                            if (respostasRecebidas == layersParaIdentificar.length) {
                                if (mappedLayer.specialPopup) {
                                    let respostaVazia = responses[0].dados.features.length == 0;
                                    if (!respostaVazia) {
                                        console.log('ESTA CAMADA TEM POPUP ESPECIAL!');
                                        //this.camadaCamerasService.openModal(responses[0].dados.features);
                                        this.getLayerHandler(mappedLayer);
                                        mappedLayer.layerHandler.openModal(responses[0].dados.features);
                                    }
                                } else {
                                    this.mostrarDadosMultiplosGetFeatureInfo(responses, mappedLayer);
                                }
                            }
                        }
                    );
                }
                catch (ex) {

                }
            });
        });
    }

    constructor(private filtroCamadaService: FiltroCamadaService, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
