import { Injectable } from '@angular/core';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { BaseService } from '@xplat-angular-workspace/core/base';

@Injectable()
export class CustomModalService extends BaseService {
    private modals: any[] = [];

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, data?) {
        // open modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.open(data);
    }

    close(id: string) {
        // close modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.close();
    }

    constructor(protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
