import { InjectionToken, Type } from '@angular/core';

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export const PlatformLanguageToken = new InjectionToken<string>(
    'PlatformLanguage'
);

// export const URL_API_POAEMACAO = 'https://poaemacao-api.procempa.com.br';
// export const URL_API_POAEMACAO = 'https://poaemacao-api-des.procempa.com.br';
// export const URL_API_POAEMACAO = 'https://api.procempa.com.br/apiman-gateway/tser/poaemacao-api-h/1.0';
export const URL_API_POAEMACAO = 'https://api.procempa.com.br/apiman-gateway/procempa/poaemacao-api-p/1.0';
// export const URL_API_POAEMACAO_P_API_KEY = '461aef47-b65c-4b8b-a3a2-a3c04ec839ad';
// export const URL_API_POAEMACAO = `http://localhost:3000`;
export const MAP_SOURCE_ARCGIS = 'ArcGIS';
export const MAP_SOURCE_GEOSERVER = 'GeoServer';
export const MAP_SOURCE_CUSTOM_GEOJSON = 'CustomGeoJSON';
export const APP_REQUIRED_KC_MAIN_ROLE = 'aplicacao-portal-user';
export const AUDITORIA_REQUIRED_KC_ROLE = 'aplicacao-portal-auditoria';
export const ADMINISTRACAO_REQUIRED_KC_ROLE = 'acesso-administrador';
export const HORUS_STREAMING_REQUIRED_KC_ROLE = 'horus-streaming-view';

// Tokens Coletor POA:
export const APP_COLETORPOA_REQUIRED_KC_MAIN_ROLE = 'coletor-poa-user';

// Tokens G4A Apps:
export const URL_G4A_API = 'https://g4a-api-des.procempa.com.br';