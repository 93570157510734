import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import localPt from '@angular/common/locales/pt';

// libs
import { environment } from '@xplat-angular-workspace/core';

// app
import { AppModule } from './app/app.module';
import { registerLocaleData } from '@angular/common';

if (environment.production) {
    enableProdMode();
}

registerLocaleData(localPt, 'pt');

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(e => console.error(e));
})