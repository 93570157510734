import { NgModule } from '@angular/core';

// xplat
import { PeaCoreModule } from '@xplat-angular-workspace/web';
import { CORE_PROVIDERS } from './services';

@NgModule({
  imports: [PeaCoreModule],
  providers: [...CORE_PROVIDERS]
})
export class CoreModule {}
