import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";

import { BaseService } from '../base/base-service';
import { URL_API_POAEMACAO } from '@xplat-angular-workspace/core/services/tokens';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class CameraService extends BaseService {
    static INTERVAL_POLLING_CAMERA_STATUS = 5000;

    // public startRadiosPolling() {
    //     this.pollingRadios = true;
    // }

    getCameraStatus(cameraData): Observable<any> {
        return this.http.get(`${URL_API_POAEMACAO}/svc-camera-status?cam=${cameraData.name}&url=${cameraData.url}&apikey=${this.getApiKey()}`)
            .pipe(
                catchError(this.handleError('getCameraStatus', {}))
            );
    }

    getCameraThumbnail(cameraData) {
        // Attempt to download the image data via an XMLHttpRequest.
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            let results;
            xhr.onload = function () {
                if (this.status != 200) {
                    console.log(`FAILED getCameraThumbnail. Status ${this.status}`);
                    return reject();
                }
                // Blob > ArrayBuffer: http://stackoverflow.com/a/15981017/4200092
                var reader = new FileReader();
                reader.onload = function () {
                    // TypedArray > Base64 text: http://stackoverflow.com/a/12713326/4200092
                    var data = String.fromCharCode.apply(null, new Uint8Array(this.result as any));
                    if (data.startsWith('<?xml')) {
                        var indexInicioMensagem = data.indexOf('<Message>');
                        var indexFimMensagem = data.indexOf('</Message>');
                        var mensagem = data.substring(indexInicioMensagem, indexFimMensagem);
                        if (mensagem) {
                            // $('#imgCamera').hide();
                            // $('#msgCamera').html(mensagem);
                            // $('#msgCamera').show();
                            results = { img: false, return: mensagem };
                        }
                    } else {
                        // $('#imgCamera').show();
                        // $('#msgCamera').hide();
                        // els.src = 'data:image/jpeg;base64,' + btoa(data);
                        results = { img: true, return: 'data:image/jpeg;base64,' + btoa(data) };
                    }
                    return resolve(results);
                };
                reader.readAsArrayBuffer(this.response);
            };
            xhr.open('get', `${URL_API_POAEMACAO}/svc-camera-thumbnail?srv=${cameraData.url}&cam=${cameraData.name}&apikey=${this.getApiKey()}`, true);
            xhr.responseType = "blob";
            xhr.send();
        });

        return promise;
    }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
