import { NgModule } from '@angular/core';

// xplat
import { UIModule } from '@xplat-angular-workspace/web';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

const MODULES = [UIModule];

@NgModule({
    imports: [...MODULES],
    exports: [...MODULES, FontAwesomeModule]
})
export class SharedModule { }
