import { Injectable } from '@angular/core';
import { BaseService } from '@xplat-angular-workspace/core/base';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from "rxjs/internal/operators/catchError";
import { URL_API_POAEMACAO } from '@xplat-angular-workspace/core/services/tokens';
import { map } from 'rxjs/operators';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class AuditoriaService extends BaseService {
    getAcessosPorAplicacao(pageSize = 50, page = 0): Observable<any> {
        // debugger;
        return this.http.get(`${URL_API_POAEMACAO}/odata/auditoria/AuditLog?$filter=app eq 'poaemacao'&$skip=${page * pageSize}&$top=${pageSize}&$orderby=timestamp desc&apikey=${this.getApiKey()}`)
            .pipe(
                map(res => {
                    // debugger;
                    return (res as any).value;
                }),
                catchError(this.handleError('getAcessosPorAplicacao', []))
            );
    }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
