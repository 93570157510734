import { Component } from '@angular/core';

// xplat
import { AppBaseComponent } from '@xplat-angular-workspace/web';

@Component({
    selector: 'pea-root',
    templateUrl: './app.component.html'
})
export class AppComponent extends AppBaseComponent {
    constructor() {
        super();
    }
}
