import { BaseComponent, CustomModalService } from '@xplat-angular-workspace/core';

export abstract class CustomModalBaseComponent extends BaseComponent {
    // openModal(id: string) {
    //     this.customModalService.open(id);
    // }

    closeModal(id: string) {
        this.customModalService.close(id);
    }

    constructor(protected customModalService: CustomModalService) {
        super();
    }
}