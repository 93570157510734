import { Injectable } from '@angular/core';
import { BaseService } from '@xplat-angular-workspace/core/base';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { URL_G4A_API } from '@xplat-angular-workspace/core/services/tokens';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class G4AService extends BaseService {
    //TODO: configurável na aplicação gerencial?
    public static PRESENTATION_TABLE_IGNORE_FIELDS = ['geom'];
    public static PRESENTATION_TABLE_ID_FIELD = 'id';
    public static PRESENTATION_GEOM_FIELD = 'geom';

    getApplicationData(workspace, application): Promise<any> {
        // debugger;
        // return this.http.get(`${URL_G4A_API}/external/workspaces/${workspace}/applications/${application}&apikey=${this.getApiKey()}`)
        return this.http.get(`${URL_G4A_API}/external/workspaces/${workspace}/applications/${application}`)
            .pipe(
                catchError(this.handleError('getApplicationData', { success: false }))
            ).toPromise();
    }

    getPagedData(workspace, table, pageData): Observable<any> {
        ///workspaces/:workspacename/tables/:tableresourceid/page
        //page=25&size=30&prop=id&dir=desc
        return this.http.get(`${URL_G4A_API}/workspaces/${workspace}/tables/${table}/page?page=${pageData.page + 1}&size=${pageData.size}&prop=${pageData.prop}&dir=${pageData.dir}`)
            .pipe(
                catchError(this.handleError('getPagedData', { success: false }))
            );
    }

    saveData(workspace, table, data): Observable<any> {
        return this.http.post(`${URL_G4A_API}/workspaces/${workspace}/tables/${table}/data`, data)
            .pipe(
                catchError(this.handleError('saveData', { success: false }))
            );
    }

    saveFeatures(workspace, application, layerData, insertData, updateData, deleteData): Observable<any> {
        let insertDataMapped = this.prepareFeaturesToServer(insertData);
        let updateDataMapped = this.prepareFeaturesToServer(updateData, true);
        let deleteDataMapped = this.prepareFeaturesToServer(deleteData, true);

        let payload = {
            inserts: insertDataMapped,
            updates: updateDataMapped,
            deletes: deleteDataMapped
        }

        return this.http.post(`${URL_G4A_API}/external/workspaces/${workspace}/applications/${application}/layers/${layerData.g4a_resourceId}/features`, payload)
            .pipe(
                catchError(this.handleError('saveFeatures', { success: false }))
            );

        // return of(payload);
    }

    private prepareFeaturesToServer(features, needId = false) {
        console.log('prepareFeaturesToServer, inp features:', features);
        return features.map(i => {
            let mappedObj = null;
            if (i.getLatLngs) {
                console.log('prepareFeaturesToServer, i.getLatLngs():', i.getLatLngs());

                mappedObj = {
                    latlngs: i.getLatLngs().map(ll => {
                        if (!Array.isArray(ll)) {
                            return { lat: ll.lat, lng: ll.lng }
                        } else {
                            return ll.map(ll2 => {
                                return { lat: ll2.lat, lng: ll2.lng }
                            })
                        }
                    })
                } as any;
            } else {
                let latLng = i.getLatLng();
                console.log('prepareFeaturesToServer, i.getLatLng():', latLng);

                mappedObj = {
                    latlngs: [{ lat: latLng.lat, lng: latLng.lng }]
                } as any;
            }

            if (needId && i.feature) {
                mappedObj.id = i.feature.id;
            }
            
            console.log('prepareFeaturesToServer, out mappedObj:', mappedObj);
            return mappedObj;
        });
    }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
