import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';

import { BaseService } from '../base/base-service';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class IntegracaoALPRService extends BaseService {
    static INTERVAL_POLLING_HORUS = 30000;
    static INTERVAL_POLLING_OCORRENCIAS_ALPR = 5000;

    getPosicoesHorus(): Observable<any> {
        return (
            this.http
                .get(
                    `https://api.procempa.com.br/apiman-gateway/procempa/alpr-gps/1.0?apikey=${this.getApiKey()}`
                )
                // .pipe(catchError(_ => { return [] }));
                .pipe(catchError(this.handleError('getPosicoesHorus', {})))
        );
    }

    getOcorrenciasALPR(): Observable<any> {
        return (
            this.http
                .get(
                    //RK - alterado em 26/07/2019 a pedido do Márcio
                    //`https://api.procempa.com.br/apiman-gateway/procempa/alpr-ocorrencias/1.0?apikey=${this.getApiKey()}&onlyAlerts=Y&alertDetails=ONLY_RECOGNIZED`
                    //`https://alpr.procempa.com.br/plateRecSystemControlPanel/rest/service/vehicle/mobile/list/latest?onlyAlerts=Y&alertDetails=ONLY_RECOGNIZED`
                    //`https://dev.procempa.com.br/plateRecSystemControlPanel/rest/service/vehicle/mobile/list/latest?onlyAlerts=Y&alertDetails=ONLY_RECOGNIZED`
                    //`https://dev.procempa.com.br/plateRecSystemIntegrator/rest/service/vehicle/mobile/list/latest?onlyAlerts=Y&alertDetails=ONLY_RECOGNIZED`
                    `https://alpr.procempa.com.br/plateRecSystemIntegrator/rest/service/vehicle/mobile/list/latest?onlyAlerts=Y&alertDetails=ONLY_RECOGNIZED`
                )
                // .pipe(catchError(_ => { return [] }));
                .pipe(catchError(this.handleError('getOcorrenciasALPR', [])))
        );
    }

    enviarMensagemHorus(mensagem, usuario): Observable<any> {
        const formData: FormData = new FormData();
        formData.append("agent", usuario);
        formData.append("message", mensagem);

        return (
            this.http
                .post(
                    `https://alpr.procempa.com.br/plateRecSystemIntegrator/rest/service/vehicle/gps/broadcast`,
                    formData,
                    { observe: 'response' }
                )
                .pipe(catchError(this.handleError('enviarMensagemHorus', {})))
        );
    }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
