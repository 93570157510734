import { Component } from '@angular/core';

import { BaseComponent, AUDITORIA_REQUIRED_KC_ROLE, ADMINISTRACAO_REQUIRED_KC_ROLE } from '@xplat-angular-workspace/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Component({
    selector: 'pea-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss']
})
export class HeaderComponent extends BaseComponent {
    'sign-out' = faSignOutAlt;
    
    constructor(private keycloakService: KeycloakService) {
        super();
    }

    logout() {
        this.keycloakService.logout();
    }

    get AUDITORIA_REQUIRED_KC_ROLE() {
        return AUDITORIA_REQUIRED_KC_ROLE;
    }

    get ADMINISTRACAO_REQUIRED_KC_ROLE() {
        return ADMINISTRACAO_REQUIRED_KC_ROLE;
    }
}