import { FilterFieldBase } from './filter-field-base';

export class TextboxFilterField extends FilterFieldBase<string> {
    controlType = 'textbox';
    type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || '';
    }
}