import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";

import { BaseService } from '../base/base-service';
import { URL_API_POAEMACAO } from '@xplat-angular-workspace/core/services/tokens';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class RadioTeltronicService extends BaseService {
    // private pollingRadios = false;
    //static URL_API_POAEMACAO = 'https://poaemacao-api-des.procempa.com.br';
    // static URL_API_POAEMACAO = 'https://poaemacao-api.procempa.com.br';
    // static URL_API_POAEMACAO = 'http://localhost:3000';
    static INTERVAL_POLLING_RADIOS = 5000;

    // public startRadiosPolling() {
    //     this.pollingRadios = true;
    // }

    getRadios(): Observable<any> {
        return this.http.get(`${URL_API_POAEMACAO}/svc-geojson?apikey=${this.getApiKey()}`)
            // .pipe(catchError(_ => { return [] }));
            .pipe(
                catchError(this.handleError('getRadios', {}))
            );
    }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
