export const URL_APIMAN_BASE = 'https://api.procempa.com.br/apiman-gateway/tser';
export const URL_ARCGIS_DES_REST = `${URL_APIMAN_BASE}/arcgis-desenvolvimento/1.0`;
export const URL_ARCGIS_PRO_REST = `${URL_APIMAN_BASE}/arcgis-producao/1.0`;
export const URL_GEOSERVER_DES_OWS_PROPRIOS = `${URL_APIMAN_BASE}/geoserver-d-ows-proprios/1.0`;
export const URL_GEOSERVER_DES_OWS_SMS = `${URL_APIMAN_BASE}/geoserver-d-ows-sms/1.0`;
export const URL_GEOSERVER_DES_OWS_SMSEG = `${URL_APIMAN_BASE}/geoserver-d-ows-smseg/1.0`;
export const URL_GEOSERVER_DES_OWS_SMURB = `${URL_APIMAN_BASE}/geoserver-d-ows-smurb/1.0`;
export const URL_GEOSERVER_DES_OWS_DEP = `${URL_APIMAN_BASE}/geoserver-d-ows-dep/1.0`;
export const URL_GEOSERVER_DES_OWS_POAEMACAO = `${URL_APIMAN_BASE}/geoserver-d-ows-poaemacao/1.0`;
export const URL_GEOSERVER_PRO_OWS_CAMERAS = `${URL_APIMAN_BASE}/geoserver-p-ows-cameras/1.0`;
export const URL_GEOSERVER_PRO_WPS_UNIQUE = `${URL_APIMAN_BASE}/geoserver-p-wps-unique/1.2`;
export const URL_GEOSERVER_PRO_REST_STYLES = `${URL_APIMAN_BASE}/geoserver-p-rest-styles/1.0`;
export const URL_GEOSERVER_DES_WPS_UNIQUE = `${URL_APIMAN_BASE}/geoserver-d-wps-unique/1.0`;
export const URL_GEOSERVER_DES_REST_STYLES = `${URL_APIMAN_BASE}/geoserver-d-rest-styles/1.2`;
export const URL_GEOSERVER_PRO_OWS_POAEMACAO = `${URL_APIMAN_BASE}/geoserver-p-ows-poaemacao/1.0`;
export const URL_GEOSERVER_DES_OWS_COLETORPOA = `${URL_APIMAN_BASE}/geoserver-d-ows-coletorpoa/1.0`;