import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cssClass',
    pure: true
})
export class CssClassPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            return value.toLowerCase().replace(/\s/g, '_');
        }
    }
}
