import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";

import { BaseService } from '@xplat-angular-workspace/core/base';
import { tap } from 'rxjs/operators';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class ArcGisService extends BaseService {
    doDiscoverFields(layerData): Observable<any> {
        return this.http.get(layerData.fieldDiscoveryUrl)
            .pipe(
                catchError(this.handleError('doDiscoverFields', {}))
            );
    }

    fieldTypeMapper() {
        return {
            esriFieldTypeInteger: 'number',
            esriFieldTypeString: 'text',
            esriFieldTypeOID: 'number'
        }
    }

    queryResultsToGeoJSON(results) {
        //TODO: Só está sendo feito o Geometry, para mostrar no resultado da busca
        let geojson = { type: 'FeatureCollection', totalFeatures: results.features.length, features: [] };
        results.features.map(f => {
            geojson.features.push(
                {
                    type: 'Feature',
                    geometry: {
                        type: this.geoJSONTypeMapper()[results.geometryType],
                        coordinates: [f.geometry.x, f.geometry.y]
                    }
                }
            );
        });

        return geojson;
    }

    getLegends(layerData): Observable<any> {
        return this.http.get(layerData.getLegendUrl)
            .pipe(
                catchError(this.handleError('getLegends', {}))
            );
    }

    doGetFeature(layerData, filterValues, isFilter = false): Observable<any> {
        let whereClause = [];
        Object.keys(filterValues).map(fv => {
            let filterValue = filterValues[fv] as string;
            if (filterValue != '') {
                let viewField = this.getFilterViewField(layerData.viewFields, fv);
                if (viewField.type == 'number') {
                    whereClause.push(`${fv} = ${parseInt(filterValue)}`);
                } else {
                    whereClause.push(`LOWER(${fv}) LIKE '%${filterValue.toLowerCase()}%'`);
                }
            }
        });
        let whereClauseString = whereClause.join(' AND ');
        //TODO: Como fica com o ArcGIS?
        // let cqlFilterFromLayer = layerData.layerOptions.cql_filter;
        // if (cqlFilterFromLayer) {
        //     whereClauseString += ` AND (${cqlFilterFromLayer})`;
        // }

        if (isFilter) {
            return new Observable((observer) => {
                // observable execution
                observer.next({ appliedFilter: whereClauseString });
                observer.complete();
            });
            // return of({appliedCqlFilter: cqlFilterString });
        } else {
            return this.http.get(`${layerData.getFeatureUrl}&where=${whereClauseString.replace(/%/g, '%25')}`)
                .pipe(
                    tap(res => { (res as any).appliedFilter = whereClauseString }),
                    catchError(this.handleError('doGetFeature', {}))
                );
        }
    }

    private getFilterViewField(viewFields, key) {
        return viewFields.filter(vf => {
            return vf.key == key;
        })[0];
    }

    private geoJSONTypeMapper() {
        return {
            esriGeometryPoint: 'Point'
        }
    }

    // getDiscoveredFields(mappedDiscovery, layerName) {
    //     mappedDiscovery = mappedDiscovery.featureTypes.filter(ft => {
    //         return ft.typeName == layerName;
    //     })[0];

    //     return mappedDiscovery;
    // }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);
    }
}
