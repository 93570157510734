import { FilterFieldBase } from './filter-field-base';

export class DropdownFilterField extends FilterFieldBase<string> {
    controlType = 'dropdown';
    options: { key: string, value: string }[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
    }
}