import { OnDestroy, ReflectiveInjector } from '@angular/core';

// libs
import { Subject } from 'rxjs';

// TODO: Add Angular decorator.
export abstract class BaseComponent implements OnDestroy {
    public destroy$: Subject<any> = new Subject();

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    injectService<T>(i: { new(): T; }): T {
        return ReflectiveInjector.resolveAndCreate([i]).get(i);
    }
}
