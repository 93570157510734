import { Injectable } from '@angular/core';
import { GeoServerService, MAP_SOURCE_GEOSERVER, MAP_SOURCE_CUSTOM_GEOJSON, MAP_SOURCE_ARCGIS, ArcGisService } from '@xplat-angular-workspace/core';
import { FilterFieldBase } from '../models/filter-field-base';
import { TextboxFilterField } from '../models/textbox-filter-field';
import { DropdownFilterField } from '../models/dropdown-filter-field';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, empty, forkJoin, of, Observable, interval } from 'rxjs';
import { MapaService } from './mapa.service';
import { tap, mergeMap, map, takeWhile, switchMap, startWith } from 'rxjs/operators';
import { cloneObject } from '@xplat-angular-workspace/utils';

@Injectable()
export class FiltroCamadaService {
    public mappedLayersFilters = [];
    private mappedDiscoveries = {};
    private mappedLayersFiltersSource = new BehaviorSubject<Array<any>>([]);
    public mappedLayersFilters$ = this.mappedLayersFiltersSource.asObservable();
    private searchResultsSource = new BehaviorSubject<any>({});

    // set pollingCameraStatus(newValue: boolean) {
    //     this._pollingCameraStatus = newValue;
    //     if (!newValue && this.currentCameraStatusSubscription) {
    //         this.currentCameraStatusSubscription.unsubscribe();
    //     }
    // }

    // get mappedLayersFilters() {
    //     return this._mappedLayersFilters;
    // }

    get searchResults$() {
        return this.searchResultsSource.asObservable();
    }

    mapLayerFilter(layerInfo) {
        if (!this.getMappedLayerFilter(layerInfo.layerData.title)) {
            let layerIDSplitted;
            try {
                layerIDSplitted = layerInfo.layerData.layerID.split(':') as Array<any>;
            } catch (ex) {
                if (layerInfo.layerData.source == MAP_SOURCE_ARCGIS) {
                    layerIDSplitted = [`${layerInfo.layerData.title}:${layerInfo.layerData.layerID}`];
                } else {
                    layerIDSplitted = [layerInfo.layerData.layerID];
                }
            }
            let layerNamespace = layerIDSplitted[0];
            let layerName = layerIDSplitted.length > 1 ? layerIDSplitted[1] : layerNamespace;
            layerInfo.layerData.layerNamespace = layerNamespace;
            layerInfo.layerData.layerName = layerName;
            this.mappedLayersFilters.push(layerInfo.layerData);

            if (!this.mappedDiscoveries[layerNamespace]) {
                this.mappedDiscoveries[layerNamespace] = true;
                if (layerInfo.layerData.source == MAP_SOURCE_GEOSERVER) {
                    this.geoServerService.doDiscoverFields(layerInfo.layerData).subscribe(res => {
                        this.mappedDiscoveries[layerNamespace] = res;
                        this.setDiscoveredFields(layerInfo);
                    });
                    this.geoServerService.getLayerSLD(layerInfo.layerData).subscribe(res => {
                        // console.log(res);
                        layerInfo.layerData.filterFromMapSources = res;
                    });
                } else if (layerInfo.layerData.source == MAP_SOURCE_ARCGIS) {
                    this.geoServerService.doDiscoverFields(layerInfo.layerData).subscribe(res => {
                        this.mappedDiscoveries[layerNamespace] = res;
                        this.setDiscoveredFields(layerInfo);
                    });
                } else if (layerInfo.layerData.source == MAP_SOURCE_CUSTOM_GEOJSON) {
                    let fields = layerInfo.layerData.layerHandler.getFields().subscribe(res => {
                        if (res && res.featureTypes[0].properties) {
                            this.mappedDiscoveries[layerNamespace] = res;
                            this.setDiscoveredFields(layerInfo);
                        }
                        //console.log('VEIO', res);
                    });
                }
            } else {
                this.setDiscoveredFields(layerInfo);
            }
        } else {
            this.setDiscoveredFields(layerInfo);
        }
    }

    private setDiscoveredFields(layerInfo) {
        this.getDiscoveredFields(layerInfo.layerData).subscribe(res => {
            layerInfo.layerData.discoveredFields = res;
            this.mappedLayersFiltersSource.next(this.mappedLayersFilters);
        });
        // console.log(layerInfo.layerData);
    }

    viewFieldstoFormGroup(filters: FilterFieldBase<any>[]) {
        let group: any = {};

        filters.forEach(filter => {
            group[filter.key] = /*question.required ? new FormControl(question.value || '', Validators.required)
                                                  :*/ new FormControl(filter.value || '');
        });
        return new FormGroup(group);
    }

    searchInLayer(layerData, filterValues, isFilter = false) {
        if (layerData.source == MAP_SOURCE_GEOSERVER) {
            return this.geoServerService.doGetFeature(layerData, filterValues, isFilter).pipe(
                tap(res => {
                    //this.mapaService.displaySearchResults(res);
                    this.searchResultsSource.next({ results: res, layerData, isFilter });
                }));
        } else if (layerData.source == MAP_SOURCE_ARCGIS) {
            return this.arcGisService.doGetFeature(layerData, filterValues, isFilter).pipe(
                tap(res => {
                    res = isFilter ? res : this.arcGisService.queryResultsToGeoJSON(res);
                    this.searchResultsSource.next({ results: res, layerData, isFilter });
                }));
        } else if (layerData.source == MAP_SOURCE_CUSTOM_GEOJSON) {
            let applyInterval = !isFilter && layerData.layerHandler.shouldPolling && layerData.layerHandler.enabled;
            let interval$ = interval(layerData.layerHandler.getInterval() / 2);
            //     let interval$ = interval(this.getInterval());
            // (this.shouldPolling ? interval$ : empty()).pipe(
            //     takeWhile(() => this.enabled),
            return (applyInterval ? interval$ : of()).pipe(
                takeWhile(() => layerData.layerHandler.enabled),
                startWith(0),
                switchMap(() =>
                    layerData.layerHandler.doGetFeature(layerData, cloneObject(filterValues), isFilter).pipe(
                        tap(res => {
                            this.searchResultsSource.next({ results: res, layerData, isFilter });
                        })
                    )
                )
            );
            // return layerData.layerHandler.doGetFeature(layerData, cloneObject(filterValues), isFilter).pipe(
            //     tap(res => {
            //         //this.mapaService.displaySearchResults(res);
            //         this.searchResultsSource.next({ results: res, layerData, isFilter });
            //     }));
        }

        return empty();
    }

    private getDiscoveredFields(layerData) {
        let layerNamespace = layerData.layerNamespace;
        let layerName = layerData.layerName;

        let mappedDiscovery = this.mappedDiscoveries[layerNamespace];
        if (!mappedDiscovery) {
            return of([]);
        }

        //TODO: Específico GeoServer (.featureTypes)
        if (layerData.source == MAP_SOURCE_ARCGIS) {
            // em branco
        } else {
            if (!mappedDiscovery.featureTypes) {
                return of([]);
            }
            mappedDiscovery = mappedDiscovery.featureTypes.filter(ft => {
                return ft.typeName == layerName;
            })[0];
        }

        if (!mappedDiscovery) {
            return of([]);
        } else {
            return this.buildViewFields(mappedDiscovery.properties || mappedDiscovery.fields, layerData).pipe(
                map(res => {
                    layerData.viewFields = res;
                    layerData.form = this.viewFieldstoFormGroup(layerData.viewFields);
                    return mappedDiscovery.properties;
                })
            );
        }
    }

    private getMappedLayerFilter(layerTitle: string) {
        return this.mappedLayersFilters.filter(lf => {
            return lf.title == layerTitle;
        })[0];
    }

    private isWhitelistedField(layerData, property) {
        let passes = true;
        if (layerData.config && layerData.config.filter && layerData.config.filter.whitelistedFields) {
            if (!layerData.config.filter.whitelistedFields.includes(property.name)) {
                passes = false;
            }
        }
        return passes;
    }

    private getFieldAlias(layerData, property) {
        let alias = property.alias || property.name;
        if (layerData.config && layerData.config.filter && layerData.config.filter.aliases) {
            let aliasForField = layerData.config.filter.aliases[property.name];
            if (aliasForField) {
                alias = aliasForField;
            }
        }
        return alias;
    }

    private getFetchFieldValuesFunction(layerData, property) {
        let fetchFieldFunction;
        if (layerData.config && layerData.config.filter && layerData.config.filter.fetches && layerData.config.filter.fetches[property.name]) {
            fetchFieldFunction = layerData.config.filter.fetches[property.name].getter;
        }
        return fetchFieldFunction;
    }

    private buildViewFields(layerProperties, layerData) {
        let fieldPromises: Observable<any>[] = [];

        if (layerData.source == MAP_SOURCE_ARCGIS) {
            layerProperties.map(lp => {
                if (this.isWhitelistedField(layerData, lp)) {
                    if (['esriFieldTypeInteger', 'esriFieldTypeString', 'esriFieldTypeOID'].includes(lp.type)) {
                        fieldPromises.push(
                            of(
                                new TextboxFilterField({
                                    key: lp.name,
                                    label: this.getFieldAlias(layerData, lp),
                                    type: this.arcGisService.fieldTypeMapper()[lp.type]
                                })
                            )
                        )
                    }
                }
            });
        } else {
            let isGeoServer = layerData.source == MAP_SOURCE_GEOSERVER;

            layerProperties.map((lp) => {
                if (this.isWhitelistedField(layerData, lp)) {
                    if (['string', 'number', 'boolean'].includes(lp.localType)) {
                        let fetchFieldValuesFunction = this.getFetchFieldValuesFunction(layerData, lp);
                        if (fetchFieldValuesFunction) {
                            let observerFunction = isGeoServer
                                ? this.geoServerService.getDistinctFieldValues(layerData, lp.name)
                                : layerData.layerHandler[fetchFieldValuesFunction]() as Observable<any>;
                            fieldPromises.push(observerFunction.pipe(
                                map(pv => {
                                    if (pv) {
                                        return new DropdownFilterField({
                                            key: lp.name,
                                            label: this.getFieldAlias(layerData, lp),
                                            options: pv
                                        });
                                    } else {
                                        return null;
                                    }
                                })
                            ));
                            // console.log('FETCH FEITO COM SUCESSO', res);
                        } else {
                            fieldPromises.push(
                                of(
                                    new TextboxFilterField({
                                        key: lp.name,
                                        label: this.getFieldAlias(layerData, lp),
                                    })
                                )
                            );
                        }
                    }
                }
            });
        }

        // http://www.jiodev.com/angular/learn-rxjs/forkJoin
        return forkJoin(fieldPromises).pipe(
            map(results => {
                return results.sort((a, b) => a.order - b.order);
            })
        )
    }

    constructor(private geoServerService: GeoServerService, private arcGisService: ArcGisService) { }
}
