// libs
import { Injector, ReflectiveInjector } from "@angular/core";
import { KeycloakService } from "@procempa/ngx-keycloak";
import { Subject, Observable, of } from 'rxjs';
import { LogService } from '../services/log.service';

// const keycloakService = AppInjector.get(KeycloakService);

export abstract class BaseService {
    protected log;

    constructor(protected keycloakService: KeycloakService) {
        this.log = this.inject<LogService>(LogService);
        LogService.DEBUG.LEVEL_4 = true;
        // this.keycloakService = ReflectiveInjector.resolveAndCreate([KeycloakService]).get(KeycloakService);
    }

    private getUserIdToken() {
        return (this.keycloakService.getUser() as any).idToken;
    }

    getApiKey() {
        //this.keycloakService.getKeycloakInstance().idTokenParsed
        let idToken = this.getUserIdToken();
        // if (!idToken || !this.keycloakService.getKeycloakInstance()) {
            // return null;
        // }
        return (idToken || this.keycloakService.getKeycloakInstance().idTokenParsed).apikey;
    }

    /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log.error(`${operation} falhou: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private inject<T>(i: { new(): T; }): T {
        return ReflectiveInjector.resolveAndCreate([i]).get(i);
    }
}
