import { MAP_SOURCE_ARCGIS, MAP_SOURCE_GEOSERVER, MAP_SOURCE_CUSTOM_GEOJSON } from '@xplat-angular-workspace/core';
import {
    URL_ARCGIS_DES_REST, URL_GEOSERVER_DES_OWS_SMS, URL_GEOSERVER_DES_REST_STYLES, URL_GEOSERVER_DES_WPS_UNIQUE,
    URL_GEOSERVER_PRO_OWS_CAMERAS, URL_GEOSERVER_DES_OWS_SMSEG, URL_GEOSERVER_DES_OWS_POAEMACAO, URL_GEOSERVER_PRO_OWS_POAEMACAO, URL_GEOSERVER_DES_OWS_COLETORPOA
} from '../core/local-tokens';

let config = {
    mappedLayers: {
        coletor_arvores: {
            source: MAP_SOURCE_GEOSERVER, title: 'Árvores',
            url: `${URL_GEOSERVER_DES_OWS_COLETORPOA}`,
            layerID: 'coletor-poa:coleta_arvore',
            config: {
                filter: {
                    whitelistedFields: ['username', 'guid', 'tipo_arvore', 'tamanho_copa']
                },
                identify: {
                    whitelistedFields: ['username', 'guid', 'tipo_arvore', 'tamanho_copa', 'ids_imagens_minio'],
                    aliases: {
                        ids_imagens_minio: 'Fotos'
                    },
                    media: {
                        ids_imagens_minio: { type: 'image', baseUrl: 'http://lpmpa-dockerh13:9045/minio/download/arvore/[id]?token=' }
                    }
                }
            }
        }
    }
}

export { config };