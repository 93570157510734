import { Injectable } from '@angular/core';
import { catchError, tap, takeWhile, startWith, switchMap } from 'rxjs/operators';

import { BaseService } from '../base/base-service';
import { URL_API_POAEMACAO } from '@xplat-angular-workspace/core/services/tokens';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from '@procempa/ngx-keycloak';

@Injectable()
export class WowzaService extends BaseService {
    static INTERVAL_POLLING_STREAMING_STATUS = 1000;
    static TRIALS_POLLING_STREAMING_STATUS = 10;

    private pollStreamingStatusTrials = 0;
    private availableStreamFiles = [];
    private streamingBytesInSource = new BehaviorSubject<any>(0);
    public streamingBytesIn$ = this.streamingBytesInSource.asObservable();

    private getAvailableStreamFiles() {
        return this.http.get(`${URL_API_POAEMACAO}/wowza/cameras?apikey=${this.getApiKey()}`)
            .pipe(
                catchError(this.handleError('getAvailableStreamFiles', {}))
            );
    }

    private getApplicationOfStreamFile(cameraName) {
        let streamFile = this.availableStreamFiles.filter(sf => {
            return sf.id == cameraName;
        })[0];

        if (streamFile) {
            let splittedAddress = streamFile.href.split('/applications/');
            if (splittedAddress.length == 2) {
                let partWithAppName = splittedAddress[1] as string;
                let appName = partWithAppName.substring(0, partWithAppName.indexOf('/'));
                return appName;
            }
        }

        return null;
    }

    getStreamingStatus(cameraName): Observable<any> {
        let appName = this.getApplicationOfStreamFile(cameraName);
        return this.http.get(`${URL_API_POAEMACAO}/wowza/${appName}/cameras/${cameraName}/status?apikey=${this.getApiKey()}`)
            .pipe(
                catchError(this.handleError('getStreamingStatus', {}))
            );
    }

    stopStreaming(cameraName) {
        let appName = this.getApplicationOfStreamFile(cameraName);
        this.http.get(`${URL_API_POAEMACAO}/wowza/${appName}/cameras/${cameraName}/stop?apikey=${this.getApiKey()}`)
            .pipe(
                catchError(this.handleError('stopStreaming', {}))
            ).toPromise();
    }

    startStreaming(cameraName): Observable<any> {
        let appName = this.getApplicationOfStreamFile(cameraName);
        return this.http.get(`${URL_API_POAEMACAO}/wowza/${appName}/cameras/${cameraName}/start?apikey=${this.getApiKey()}`)
            .pipe(
                tap(res => {
                    (res as any).appName = appName;
                }),
                catchError(this.handleError('startStreaming', {}))
            );
    }

    startPollingStreamingBytesIn(cameraName) {
        this.pollStreamingStatusTrials = 0;
        this.streamingBytesInSource.next(0);
        let interval$ = interval(WowzaService.INTERVAL_POLLING_STREAMING_STATUS);
        let subscription = interval$.pipe(
            takeWhile(() => this.pollStreamingStatusTrials < WowzaService.TRIALS_POLLING_STREAMING_STATUS),
            startWith(0),
            switchMap(() => this.getStreamingStatus(cameraName))
        ).subscribe(res => {
            this.pollStreamingStatusTrials++;
            if (res && res.bytesIn > 0 && res.uptime > 1) {
                this.pollStreamingStatusTrials = WowzaService.TRIALS_POLLING_STREAMING_STATUS;
                this.streamingBytesInSource.next(res.bytesIn);
                subscription.unsubscribe();
            } else if (this.pollStreamingStatusTrials == WowzaService.TRIALS_POLLING_STREAMING_STATUS) {
                this.streamingBytesInSource.next(null);
                subscription.unsubscribe();
            }
        });
    }

    constructor(private http: HttpClient, protected keycloakService: KeycloakService) {
        super(keycloakService);

        this.getAvailableStreamFiles().subscribe(res => {
            this.availableStreamFiles = res as Array<any>;
        });
    }
}
