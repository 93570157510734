// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// app
import { SharedModule } from './features/shared/shared.module';
import { APP_COLETORPOA_REQUIRED_KC_MAIN_ROLE } from '@xplat-angular-workspace/core';
import { KeycloakAuthGuard } from '@procempa/ngx-keycloak';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: './features/home/home.module#HomeModule',
        canLoad: [KeycloakAuthGuard],
        data: {
            roles: [APP_COLETORPOA_REQUIRED_KC_MAIN_ROLE]
        }
    }
];

@NgModule({
    imports: [SharedModule, RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
