import { APP_INITIALIZER, NgModule } from '@angular/core';

// libs
import { environment } from '@xplat-angular-workspace/core';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// app
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HeaderComponent } from './features/shared/components';
import { KeycloakService, NgxKeycloakModule } from '@procempa/ngx-keycloak';
import { CUSTOMMODAL_COMPONENTS } from '@xplat-angular-workspace/web/features/ui/components';
import { CdlLibModule, CdlLogradouroDirective, CdlNumeroDirective } from 'procempa-cdl-lib';
import { keycloakEnvironment } from './core/environments/environment';

@NgModule({
    imports: [CoreModule, SharedModule, AppRoutingModule, CdlLibModule.forRoot(), NgxKeycloakModule.forRoot()/*LeafletModule.forRoot()*/],
    declarations: [AppComponent, HeaderComponent],
    bootstrap: [AppComponent],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initKeycloak, multi: true, deps: [KeycloakService] }
    ],
    // providers: [
    //     {
    //         provide: HTTP_INTERCEPTORS,
    //         useClass: BackendRequestInterceptor,
    //         multi: true
    //     }
    // ]
})
export class AppModule { }

// export function myHttpKeycloakInterceptor(kc: KeycloakService) {
//     const interceptor = new KeycloakHttpInterceptor(kc);
//     interceptor.urlsToIgnore = [/cdl-servico/,/arcgis-producao//*, /awesome/, /google/, /gstatic/, /flowplayer/*/];
//     return interceptor;
// }

export function initKeycloak(keycloak: KeycloakService) {
    const env = {
        url: keycloakEnvironment.KEYCLOAK_URL,
        realm: keycloakEnvironment.KEYCLOAK_REALM,
        clientId: keycloakEnvironment.KEYCLOAK_CLIENT
    };

    keycloak.urlsToIgnore = [/cdl-servico/, /arcgis-producao//*, /awesome/, /google/, /gstatic/, /flowplayer/*/];
    return () => keycloak.init(env, { onLoad: 'login-required' });
}